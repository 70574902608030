<template>
    <styled-interface>
        <template #interface-toolbar>
            <dealer-picker-global class="flex md3 xs12 mr-5" />

            <v-autocomplete
                v-model="selectedAdAccount"
                class="flex md3 xs12 mr-5"
                :items="adAccounts"
                item-text="name"
                :loading="loadingAdAccounts"
                label="Ad Account"
                return-object
                light />

            <v-autocomplete
                v-model="selectedPlay"
                class="flex md3 xs12 mr-5"
                :items="plays"
                item-text="name"
                :loading="loadingPlays"
                label="Play"
                return-object
                light />
        </template>

        <template #interface-heading>
            Deploy Play
        </template>


        <div
            v-if="!currentDealer"
            class="text-xs-center">
            <loader />
        </div>


        <template v-if="structure != null">
            <styled-card
                v-for="(campaign, campaign_key) in structure.campaigns"
                :key="campaign_key"
                class="ma-5">
                <template #heading>
                    {{ campaign.params.name }}
                </template>
                <JsonEditor
                    v-model="structure.campaigns[campaign_key].params"
                    :options="{
                        confirmText: 'confirm',
                        cancelText: 'cancel',
                    }"
                    :obj-data="campaign.params" />
                <styled-card
                    v-for="(ad_set, ad_set_key) in campaign.ad_sets"
                    :key="ad_set_key">
                    <template #heading>
                        {{ ad_set.params.name }}
                    </template>
                    <JsonEditor
                        v-model="structure.campaigns[campaign_key].ad_sets[ad_set_key].params"
                        :options="{
                            confirmText: 'confirm',
                            cancelText: 'cancel',
                        }"
                        :obj-data="ad_set.params" />
                    <styled-card
                        v-for="(ad, ad_key) in ad_set.ads"
                        :key="ad_key">
                        <template #heading>
                            {{ ad.params.name }}
                        </template>
                        <JsonEditor
                            v-model="structure.campaigns[campaign_key].ad_sets[ad_set_key].ads[ad_key].params"
                            :options="{
                                confirmText: 'confirm',
                                cancelText: 'cancel',
                            }"
                            :obj-data="ad.params" />
                        <styled-card
                            v-for="(ad_creative, ad_creative_key) in ad.ad_creatives"
                            :key="ad_creative_key">
                            <template #heading>
                                {{ ad_creative.params.name }}
                            </template>
                            <JsonEditor
                                v-model="structure.campaigns[campaign_key].ad_sets[ad_set_key].ads[ad_key].ad_creatives[ad_creative_key].params"
                                :options="{
                                    confirmText: 'confirm',
                                    cancelText: 'cancel',
                                }"
                                :obj-data="ad_creative.params" />
                            <button
                                @click="previewAd(structure.campaigns[campaign_key].ad_sets[ad_set_key].ads[ad_key].ad_creatives[ad_creative_key].params)">
                                Preview
                            </button>
                            <template v-if="adPreview != null">
                                <span v-html="adPreview" />
                            </template>
                        </styled-card>
                    </styled-card>
                </styled-card>
            </styled-card>
            <button
                @click="deploy(structure)">
                Deploy
            </button>
        </template>
    </styled-interface>
</template>

<script>
import ApiQuery from '../../api-query';
import HTTP from '../../http';
import StyledInterface from '../globals/StyledInterface';
import StyledCard from '../globals/StyledCard';
import DealerPickerGlobal from '../globals/DealerPickerGlobal';
import Loader from '../globals/Loader.vue';
import { mapState } from 'vuex';
import Vue from 'vue';
import JsonEditor from 'vue-json-edit';

Vue.use(JsonEditor);

export default {
    components: {
        DealerPickerGlobal,
        Loader,
        StyledCard,
        StyledInterface,
    },
    title: 'deploy-facebook-play',
    data() {
        return {
            raw: [],
            plays: [],
            adAccounts: [],
            adPreview: null,
            structure: null,
            errors: [],
            loadingPlays: true,
            loadingAdAccounts: false,
            selectedPlay: null,
            selectedAdAccount: null,
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer,
            user: (state) => state.user.user,
        })
    },
    watch: {
        selectedPlay() {
            this.getPlayStructure();
        },
        structure() {
            console.log(this.structure);
        },
        currentDealer() {
            this.loadingAdAccounts = true;

            (new ApiQuery('agencies/' + this.currentDealer.agency_id + '/ad_accounts'))
                .get()
                .then(response => {
                    this.adAccounts = response.data.data;
                    this.loadingAdAccounts = false;
                })
                .catch(e => {
                    console.log(e);
                });
        }
    },
    created() {


        this.$http.cancel();
        this.getPlays();
    },
    methods: {
        async getPlays() {
            this.loadingPlays = true;

            this.plays = [];

            (new ApiQuery('plays'))
                .setPageSize(1000)
                .get()
                .then(response => {
                    this.plays = response.data.data;
                    this.loadingPlays = false;
                })
                .catch(e => {
                    console.log(e);
                });
        },
        async getPlayStructure() {
            this.structure = [];

            (new ApiQuery('plays/' + this.selectedPlay.id + '/full-structure'))
                .get()
                .then(response => {
                    this.structure = response.data;
                    console.log(response.data);
                })
                .catch(e => {
                    console.log(e);
                });
        },
        async previewAd(adParams) {
            this.adPreview = null;

            (new HTTP)
                .post(
                    'ad_account/' + this.selectedAdAccount.id + '/generate-ad-preview',
                    {
                        dealer_id: this.currentDealer.id,
                        ad_params: adParams,
                        ad_format: 'DESKTOP_FEED_STANDARD'
                    }
                )
                .then((response) => {
                    console.log(response.data);
                    this.adPreview = response.data.data[0].body;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async deploy(structure) {
            console.log(structure);

            (new HTTP)
                .post(
                    'dealers/' + this.currentDealer.id + '/deploy-play',
                    {
                        structure
                    }
                )
                .then((response) => {
                    console.log(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    }
};
</script>

